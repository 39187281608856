








































































































































































































import Vue from 'vue'

import { statistics } from '@/store/event/helpers'
import stylesVars from '@/styles/colors'
import TemperatureLine from '@/components/data/common/TemperatureLine/TemperatureLine.vue'
import GraphLine from '@/components/data/common/GraphLine/GraphLine.vue'
import Histogram from '@/components/data/common/Histogram/Histogram.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import DraggableComponent from '@/components/ui/DraggableComponent/DraggableComponent.vue'

export default Vue.extend({
  name: 'overview',
  components: {
    'draggable-component': DraggableComponent,
    'temperature-line': TemperatureLine,
    histogram: Histogram,
    'graph-line': GraphLine,
    'ui-loader': Loader
  },
  props: {
    eventSessionWeatherStat: {
      type: Array,
      default: () => ([])
    },
    series: {
      type: Array,
      required: true
    },
    fontBase: Number,
    loading: Boolean,
    loadingCompletion: Number,
    isMobile: Boolean,
    sliderItemWidth: Number,
    title: {
      type: String,
      default: 'Overview'
    },
    displayTitle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      stylesVars,
      hideLegend: false,
      statistics: statistics,
      selectedSession: 'Race',
      selectedStation: 'all'
    }
  },
  computed: {
    isDisplayedNBRainfall (): boolean {
      return this.series.some(serie => serie === 'sumRainIntensity')
    },
    temperatureBottom (): string {
      return 'calc(' +
        (this.isMobile ? stylesVars.OffsetBottomTemperatureLine__mobile : stylesVars.OffsetBottomTemperatureLine__default) + ')'
    },
    allSessions (): Set<unknown> {
      return this.eventSessionWeatherStat.reduce((acc, event) => {
        event.sessions.forEach(({ labelDisplay }) => {
          acc.add(labelDisplay)
        })
        return acc
      }, new Set())
    },
    allStations (): string[] {
      const allStations = this.eventSessionWeatherStat.reduce((acc, event) => {
        event.sessions.forEach(session => {
          session.stations.forEach(({ idStation }) => {
            acc.add(idStation)
          })
        })
        return acc
      }, new Set())
      return [...allStations].sort()
    },
    dataBySerie (): { [key: string]: number|null[] } {
      return this.series.reduce((acc, stat) => {
        acc[stat] = this.getData(stat, this.selectedSession, this.selectedStation)
        return acc
      }, {})
    },
    isDataNotExistForEvent (): boolean {
      return Object.values(this.dataBySerie).every(indicator => (indicator as []).length === 0 || (indicator as []).every(data => data === null))
    },
    /**
     * Get all sessions for each year according of session type
     */
    sessionStartDateByYearAndByType (): { year: string; dateStart: string; timestamp: string }[] {
      let data = []
      if (this.eventSessionWeatherStat) {
        data = this.eventSessionWeatherStat.reduce((acc, event) => {
          const currentSession = event.sessions.find(session => {
            return session.labelDisplay === this.selectedSession
          })
          if (currentSession) {
            acc.push({
              year: event.year,
              dateStart: currentSession.sessionDateStart,
              timestamp: currentSession.sessionTimestampStart
            })
          }
          return acc
        }, [])
        data.sort((a, b) => a.timestamp - b.timestamp)
      }
      return data
    },
    sessionLength (): number {
      return Object.keys(this.sessionStartDateByYearAndByType).length
    }
  },
  methods: {
    toggleLegend () {
      this.hideLegend = !this.hideLegend
    },
    checkDataExist (stat: string) {
      return this.dataBySerie[stat]?.every(value => value === null)
    },
    getData (stat: string, selectedTypeSession: string, selectedStation: string|number): string[] {
      const data = []
      this.eventSessionWeatherStat.forEach(event => {
        const session = event.sessions.find(session => session.labelDisplay === selectedTypeSession)
        if (session) {
          const station = session.stations.find(({ idStation }) => idStation === selectedStation)
          if (station) {
            const value = station[stat] === null ? null : Number((station[stat]).toFixed())
            data.push(value)
          } else {
            data.push(null)
          }
        }
      })
      return data
    }
  }
})
